import * as React from 'react';
import {ConsumerWrapper} from './Styled';
import NumberedSelector from './NumberedSelector';

const ConsumerMarket = () => {
  return (
    <ConsumerWrapper>
      <h1>Consumer Market</h1>
      <p>
        Consumer market is soaring in many possible ways with Machine Learning
        and Artificial Intelligence. Yes, its the decade of AI.
      </p>

      <NumberedSelector data={data}>
        <p>Partner with us with for intelligent consumer market development</p>
        <div className="wrapper"></div>
      </NumberedSelector>
      <div className="section">
        <div>
          <div>
            <p>ENHANCE YOUR CONSUMER MARKETS</p>
            <h2>Zegates Product Engineering</h2>
          </div>
          <p>
            Use negates expertise in product engineering to enhance your
            consumer market goals by innovating your existing business or
            starting new one. We can bring right tech to your door step with
            minimal time to develop. And it will be always data driven and we
            will be using KPIs to manage and grow your business rapidly
          </p>
        </div>
        <div>
          <img src="/images/home/consumer-zegates.jpg" />
        </div>
      </div>
      <div className="section">
        <div>
          <img src="/images/home/consumer-netlise.jpg" />
        </div>
        <div>
          <h2>Netlise SaaS</h2>
          <p>
            Searching for an all in one product suite? We created Netlise to
            make your world easier to manage the products and achieve consumer
            market goals quickly. Netlise offers streamlined functionality for
            E-commerce, Point of sale and CRM in a SaaS platform with offline
            and online capabilities.
          </p>
        </div>
      </div>
    </ConsumerWrapper>
  );
};

const data = [
  {
    title: 'Product Innovation',
    content: (
      <>
        <h2>Innovation is growth</h2>
        <p>
          Introducing new and innovative products or services that address
          evolving consumer needs or offer unique features. Innovation can
          attract new customers and retain existing ones.
        </p>
        <p>
          Zegates has been built on the foundation of innovation and growth.
          With our product solutions you can improve customer experience in
          choosing products and matching them with other consumables.
        </p>
      </>
    ),
  },
  {
    title: 'Expanding Markets',
    content: (
      <>
        <h2>Scale exponentially without loosing anything</h2>
        <p>
          Identify new geographical areas or demographics that are underserved
          or untapped. Expanding the market reach can open up new opportunities
          for growth.Identify new geographical areas or demographics that are
          underserved or untapped. Expanding the market reach can open up new
          opportunities for growth.
        </p>
        <p>
          With proven solutions in hand to scale platforms to geo servers and
          scaling accessible data with high availability using architectures
          such as Data Lake you can always conquer your markets with ease
          without compromising performance or services.
        </p>
      </>
    ),
  },
  {
    title: 'E-commerce & Online Presence',
    content: (
      <>
        <h2>Scale exponentially without sacrificing</h2>
        <p>
          Invest in e-commerce platforms and establish a strong online presence.
          With the increasing trend of online shopping, having a robust digital
          presence can significantly expand your consumer reach.
        </p>
        <p>
          With proven solutions in hand to scale platforms to geo servers and
          scaling accessible data with high availability using architectures
          such as Data Lake you can always conquer your markets with ease
          without compromising performance or services.
        </p>
      </>
    ),
  },
  {
    title: 'Competitive Pricing',
    content: (
      <>
        <h2>Price with best possible combinations</h2>
        <p>
          Implement competitive and flexible pricing strategies to attract
          price-sensitive consumers. Special promotions, discounts, and loyalty
          incentives can stimulate demand and contribute to market growth.
        </p>
        <p>
          Compare prices of your competitors and decide what suites the best for
          the pricing fluctuation and group them in order to achieve the best
          pricing strategy
        </p>
        <p>
          Leverage data analytics to understand consumer behavior, preferences,
          and trends. Informed decision-making based on data insights can lead
          to more effective marketing strategies and product development.
        </p>
      </>
    ),
  },
];

export default ConsumerMarket;
